import React, { useState, useEffect } from "react";
import MovieCard from "./MovieCard";
import SearchIcon from "./search.svg";
import "./app.css";

const API_URL = "https://www.omdbapi.com?apikey=12b4f0d8";

const App = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [movies, setMovies] = useState([]);
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    searchMovies("Batman"); // Default search
  }, []);

  const searchMovies = async (title) => {
    const response = await fetch(`${API_URL}&s=${title}`);
    const data = await response.json();

    if (data.Search) {
      const moviesWithDetails = await Promise.all(
        data.Search.map(async (movie) => {
          const detailsResponse = await fetch(`${API_URL}&i=${movie.imdbID}`);
          const detailsData = await detailsResponse.json();
          return detailsData;
        })
      );

      setMovies(moviesWithDetails);
    } else {
      setMovies([]);
    }
  };

  const handleSearch = () => {
    if (searchTerm.trim() !== "") {
      searchMovies(searchTerm);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleMovieClick = (movie) => {
    setSelectedMovie(movie);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedMovie(null);
    setShowModal(false);
  };

  return (
    <div className="app">
      <h1>ENTERTAINMENT ZONE!</h1>

      <div className="search">
        <input
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search for movies"
          onKeyPress={handleKeyPress}
        />
        <img src={SearchIcon} alt="search" onClick={handleSearch} />
      </div>

      {movies?.length > 0 ? (
        <div className="container">
          {movies.map((movie) => (
            <MovieCard key={movie.imdbID} movie={movie} onClick={() => handleMovieClick(movie)} />
          ))}
        </div>
      ) : (
        <div className="empty">
          <h2>No movies found</h2>
        </div>
      )}

      {/* Movie Details Modal */}
      {showModal && selectedMovie && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <h2>{selectedMovie.Title} ({selectedMovie.Year})</h2>
            <img 
              src={selectedMovie.Poster !== "N/A" ? selectedMovie.Poster : "https://via.placeholder.com/400"} 
              alt={selectedMovie.Title} 
            />
            <p><strong>Genre:</strong> {selectedMovie.Genre}</p>
            <p><strong>IMDb Rating:</strong> {selectedMovie.imdbRating}</p>
            <p><strong>Plot:</strong> {selectedMovie.Plot}</p>
            <p><strong>Director:</strong> {selectedMovie.Director}</p>
            <p><strong>Actors:</strong> {selectedMovie.Actors}</p>
            <p><strong>Runtime:</strong> {selectedMovie.Runtime}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
